import arrow1 from "../../../assets/Icons/left-top.png"
import arrow2 from "../../../assets/Icons/right-top.png"
import arrow3 from "../../../assets/Icons/left-bottom.png"
import arrow4 from "../../../assets/Icons/right-bottom.png"


const Overlapper = () => {
    return (
        <div className="h-full w-full absolute top-0 left-0 flex flex-col p-4">
            <div className="flex justify-between">
                <img src={arrow1} alt="arrow1" className="w-10 h-10" />
                <img src={arrow2} alt="arrow2" className="w-10 h-10" />
            </div>
            <div className="flex flex-col items-center justify-between flex-grow py-3">
                <p className='bg-white text-xs font-semibold rounded-full px-4 py-3 sm600:text-2xl'>
                    Tap anywhere to capture
                </p>
                <p className='bg-white text-xs text-center w-60 font-semibold rounded-lg px-4 py-3 sm600:text-2xl sm600:w-full'>
                    Align your head, smile and tap the screen to snap the pic.
                </p>
            </div>
            <div className="flex justify-between">
                <img src={arrow3} alt="arrow3" className="w-10 h-10" />
                <img src={arrow4} alt="arrow4" className="w-10 h-10" />
            </div>
        </div>
    )
}

export default Overlapper