import welcomeTeeth from "../../assets/illustrations/welcomeTeeth.png";
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png";
import { useEffect, useState } from "react";
import { generateRandomAlphaNumeric } from "../../Utils/alphaNumeric";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { postLogDetails } from "../../Services/APIs/appDetails";

const FLandingPage = () => {

    const navigate = useNavigate()
    const storedUid = localStorage.getItem("usertpi")
    const initialId = storedUid ? JSON.parse(storedUid).usertpi : ''
    const [uid, setUid] = useState(initialId)
    const { images } = useSelector((state) => state.fullScan);

    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "toothlens" }));
    localStorage.setItem("isAutomated", true)
    localStorage.removeItem("scanType");

    useEffect(() => {
        !localStorage.getItem("reloaded") && reload()
        if (!uid || images.length === 0) {
            generateInfo()
        }
    }, [])

    const reload = () => {
        localStorage.setItem("reloaded", true)
        window.location.reload()
    }

    const generateInfo = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "toothlens" }));
        setUid(unique)
        postLogDetails({uid:unique, app : "toothlens",payLoad:`visited landing page`})
    }

    const openCamera = () => {
        navigate("/selfie/scan")
    }

    const newScan = () => {
        localStorage.clear()
        localStorage.setItem("isAutomated", true)
        localStorage.setItem("reloaded", true)
        generateInfo()
        openCamera()
    }


    return (
        <div className="h-full">
            <div className="h-1/2">
                <img
                    src={welcomeTeeth}
                    alt="welcome teeth"
                    className="saturation h-full w-full object-contain"
                />
            </div>
            <div className="h-1/2 relative flex flex-col justify-center">
                <h5
                    className=" text-2xl  font-medium text-center mb-3"
                >
                    Instant Dental Health Scan
                </h5>
                <div
                    className="text-lg font-medium text-center text-black opacity-60 mb-3"
                >
                    Snap a photo for
                    <br /> AI-powered insights
                </div>

                <div>
                    <div className="h-48  flex flex-col items-center px-4 py-2">
                        {

                            localStorage.getItem("isScanCompleted") ? <div>
                                <p className=" text-xl text-green-600 font-medium text-center my-2">You have successfully completed your scan </p>
                                <button
                                    type="button"
                                    className=" bg-black rounded text-white w-full h-11 font-semibold text-base mb-4"
                                    onClick={newScan}
                                >
                                    Take New Scan
                                </button>
                            </div> :

                                <button
                                    type="button"
                                    className=" bg-black rounded text-white w-full h-11 font-semibold text-base mb-4"
                                    onClick={openCamera}
                                >
                                    Scan
                                </button>
                        }

                    </div>
                </div>
                <div className="absolute bottom-5 w-full px-4">
                    <p className="flex items-center justify-center">
                        <span className="opacity-60"> Powered by </span>
                        <img
                            src={toothlensLogo}
                            alt="welcome teeth"
                            className=" ml-2 w-24"
                        />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FLandingPage