import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import welcomeTeeth from "../../assets/illustrations/welcomeTeeth.png";
import "../../App.css";
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png";
import { getTpStatus } from "../../Services/APIs/scan";
import { getTpScanAppDetails, postLogDetails } from "../../Services/APIs/appDetails";
import PageNotFound from "../PageNotFound";
import { generateRandomAlphaNumeric } from "../../Utils/alphaNumeric";

const TpScanLanding = () => {
    const [error, setError] = useState();
    const navigate = useNavigate();
    const [uiLoading, setUiLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const { tpscanName, uid } = useParams();
    const companyBucket = tpscanName;

    localStorage.setItem(
        "companyBucket",
        JSON.stringify({ companyBucket: tpscanName })
    );
    localStorage.setItem("usertpi", JSON.stringify({ usertpi: uid }));
    localStorage.removeItem("isAutomated");
    localStorage.removeItem("scanType");

    const takeScan = () => {
        if (tpscanName === "benepower") {
            const randomString = generateRandomAlphaNumeric(2);
            const timestamp = Date.now().toString();
            const randomString2 = generateRandomAlphaNumeric(1)
            const unique = randomString + timestamp + randomString2
            localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
            localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: tpscanName }));
        }

        navigate(`/tpscan/${tpscanName}/onboarding`);
    };

    const initialCall = async () => {
        setLoading(true)
        setError("")
        await getTpScanAppDetails({ c: tpscanName }).then(async (res) => {    // where c is the name of the company (third party)
            setUiLoading(false);
            localStorage.setItem("company_style_details", JSON.stringify(res.data.data));
            await getTpStatus({ uid, c: tpscanName }).then((res) => {
                if ((companyBucket === "done" || companyBucket === "hascompanies")) {
                    navigate(`/tpscan/${tpscanName}/onboarding`);
                }
                setError("")
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                err.status === 410 ? setError(`The report with ${uid} is already used!`) : setError("Something went wrong")
            });
        }).catch((err) => {
            setUiLoading(false);
            err.status === 404 ? setError("invalid route") : setError("Something went wrong")
        });

    };

    const company_style_details = JSON.parse(
        localStorage.getItem("company_style_details")
    );
    const buttonStyle = {
        backgroundColor: company_style_details
            ? `${company_style_details.button_background_color}`
            : "#000000",
    };

    const fontWeight = {
        fontWeight: company_style_details
            ? `${company_style_details.onboarding_text_font_weight}`
            : "",
    };

    const fontStyle = {
        fontFamily: company_style_details
            ? `${company_style_details.onboarding_text_font_style}`
            : "Raleway",
    };

    useEffect(() => {
        postLogDetails({
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
            app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
            payLoad: `new scan started`
        })
        localStorage.setItem("reload", true);
        initialCall();
    }, []);

    if (companyBucket === "bearn") {
        return <PageNotFound />;
    } else {
        return (
            <div className="h-full">
                <div className="h-1/2">
                    <img
                        src={welcomeTeeth}
                        alt="welcome teeth"
                        className="saturation h-full w-full object-contain"
                    />
                </div>
                {uiLoading ? (
                    <div className="loader-container">
                        <div className="loader dark-loader"></div>
                    </div>
                ) : (
                    <div className="h-1/2 relative flex flex-col justify-center">
                        <h5
                            style={{ ...fontWeight, ...fontStyle }}
                            className=" text-2xl  font-medium text-center mb-3"
                        >
                            Instant Dental Health Scan
                        </h5>
                        <div
                            style={{ ...fontStyle }}
                            className="text-lg font-medium text-center text-black opacity-60 mb-3"
                        >
                            Snap a photo for
                            <br /> AI-powered insights
                        </div>
                        <div>
                            <div className="h-48  flex flex-col items-center px-4 py-2">
                                {error ? (
                                    <h1 className="text-xl text-red-700  font-medium text-center">
                                        {error}
                                    </h1>
                                ) : (
                                    <button
                                        style={{ ...buttonStyle }}
                                        type="button"
                                        className={`rounded  w-full h-11 font-semibold text-base mb-4  ${companyBucket == "toothlens" ? "bg-black text-white" : "text-white"}`}
                                        onClick={() => { takeScan() }}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <div className="loader-container">
                                                <div className="loader dark-loader"></div>
                                            </div>
                                        ) : (
                                            <span>Scan </span>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="absolute bottom-11 w-full px-4">
                            {companyBucket === "bearn" || companyBucket === "benepower" ? (
                                <div
                                    style={{ ...fontStyle }}
                                    className=" font-medium flex justify-evenly text-center text-black opacity-60 "
                                >
                                    Images captured will be shared only with authorized dental
                                    professionals to ensure your privacy and care.
                                </div>
                            ) : (
                                <p className="flex flex-row justify-center">
                                    <span className="opacity-60"> Powered by </span>

                                    <img
                                        src={toothlensLogo}
                                        alt="welcome teeth"
                                        className="h-6 ml-2 w-24"
                                    />
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
};

export default TpScanLanding;
