import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup"
import { checkScanIdStatus } from "../../../Services/APIs/scan";
import { useNavigate } from "react-router-dom";
import { postLogDetails } from "../../../Services/APIs/appDetails";
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";

const PGCode = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        localStorage.setItem("reload", true)
        postLogDetails({app: "pg", payLoad : "pg code flow started"})

    }, [])

    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "pg" }));
    localStorage.removeItem("isAutomated")

    const validationSchema = Yup.object({
        code: Yup.string()
            .matches(/^\S+$/, 'スペースは許可されていません')  // No spaces allowed
            .length(10, "値はちょうど10文字である必要があります") // exactly 10 charecters required
            .required("コードは必須です") // required

    })

    const generateUid = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        postLogDetails({uid: unique, app: "pg", payLoad : `pg-code validated: ${localStorage.getItem("pg-code")}`})

    }

    const handleSubmit = (values) => {
        setLoading(true)
        setErrorMessage('')
        checkScanIdStatus({ verification_id: values.code }).then((res) => {
            localStorage.setItem("pg-code", values.code);
            generateUid()
            navigate(`/pg/scan`);

        }).catch((err) => {
            console.log(err)
            if (err?.response?.data?.code === 410) {
                setErrorMessage("コードの有効期限が切れています") //  code expired
            } else if (err?.response?.data?.code === 404) {
                setErrorMessage("入力されたコードは無効です")   // code is invalid
            } else {
                setErrorMessage("何かがうまくいきませんでした。後でもう一度お試しください")   // something went wrong
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className="h-full relative">
            <Formik
                initialValues={{ code: localStorage.getItem("pg-code") || "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit} >
                {() => (
                    <Form className="h-full">
                        <div className="h-1/2 flex flex-col items-center">
                            <img
                                src={welcomeTeeth}
                                alt="welcome teeth"
                                className="saturation h-4/5 object-contain"
                            />
                            <Field
                                type="text"
                                name="code"
                                className="border-2 border-gray-400 w-3/4 md:1/5 text-base p-2 shadow-lg rounded-md"
                                placeholder="ここにコードを入力してください..."
                            />
                            <ErrorMessage name="code" component="div" className=" text-red-500 mt-2 w-3/4 md:1/5" />
                            {errorMessage && <p className="text-red-500 mt-2 w-3/4 md:1/5">{errorMessage}</p>}
                        </div>
                        <div className="h-1/2 flex flex-col justify-center ">
                            <h5 className=" text-2xl  font-semibold text-center mb-3">
                                デンタルスキャン
                            </h5>
                            <p className="font-semibold text-center text-black opacity-60 mb-3" >
                                写真から歯の状態をAIがチェック
                            </p>
                            <div className="h-48 mt-2   flex flex-col items-center px-4 py-2">
                                <button
                                    type="submit"
                                    style={{ backgroundColor: "#1E1E78" }}
                                    className="  w-2/3 rounded-full border-2 text-white py-2 px-4 shadow-2xl font-bold "
                                >
                                    {
                                        loading ? <div className="loader-container">
                                            <div className="loader dark-loader"></div>
                                        </div> : <span> スキャン開始</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <p className="flex flex-row justify-center absolute bottom-5 w-full">
                <span className="opacity-60"> Powered by </span>
                <img
                    src={toothlensLogo}
                    alt="welcome teeth"
                    className="h-6 ml-2"
                />
            </p>
        </div>
    )

}

export default PGCode