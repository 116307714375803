import { useEffect, useState } from "react";
import cropImage from "../../../Utils/cropFace";
import { encrypt } from "../../../Utils/hmac";
import dataURItoBlob from "../../../Utils/dataUriToBlob";
import { uploadFace } from "../../../Services/APIs/scan";
import { useDispatch } from "react-redux";
import { addImages } from "../../../Store/fullScan";

const PreviewFace = (props) => {
    const dispatch = useDispatch()

    const { setImageSide, image, setCamera, setDemo, activeImageSide } = props
    const [updatedImage, setUpdatedImage] = useState(image)
    const [isLoading, setIsLoading] = useState(true)
    const [isPhotoUploading, setPhotoUploading] = useState(false)
    const [isError, setError] = useState("")
    const uid = JSON.parse(localStorage.getItem("usertpi")).usertpi

    useEffect(() => {
        crop()
        setIsLoading(false)
    }, [])

    const upload = async () => {
        try {

            setPhotoUploading(true)
            setError("")

            const data = {
                uid,
                company: JSON.parse(localStorage.getItem("companyBucket") || "{}").companyBucket,
            }
            const encrypted = encrypt(data)
            const blob = dataURItoBlob(updatedImage)
            const file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
            const formData = new FormData();
            formData.append("report_image", file);
            formData.append("_n", encrypted);

            await uploadFace({ formData }).then((response) => {
                const report = {
                    title: "Face",
                    data: URL.createObjectURL(blob),
                    file: response.data.data?.fullUrl
                }
                dispatch(addImages(report))
                setImageSide(activeImageSide + 1)
                setDemo(true)
            }).catch(error => {
                setError("Something went wrong")
            }).finally(() => {
                setPhotoUploading(false)
            })
        }
        catch (error) {

        }
    }

    const crop = () => {
        cropImage(image, (croppedDataURI) => {
            if (croppedDataURI) {
                setUpdatedImage(croppedDataURI);
            } else {
                console.error("Cropping failed.");
            }
        });

    }
    return (
        <div className="h-full w-full flex flex-col items-center justify-center">
            {
                isLoading ? <p>Please wait...</p> :
                    <img
                        src={updatedImage}
                        className="h-1/2 rounded-3xl"
                        alt="face"
                    />

            }
            <p className="text-lg text-red-600 font-semibold my-2">{isError}</p>
            <div className=" w-full flex justify-evenly p-5">
                <button
                    className=" bg-black text-white font-semibold w-full p-3 me-3 rounded"
                    onClick={() => { setCamera(true) }}>
                    Reshoot

                </button>
                <button
                    className="bg-black text-white font-semibold w-full p-3 rounded"
                    onClick={upload}>
                    {
                        isPhotoUploading ? <div className="loader-container">
                            <div className="loader"></div>
                        </div> : <span> Next</span>
                    }
                </button>
            </div>
        </div>


    )
}

export default PreviewFace